import Vue from 'vue';

export default Vue.extend({
	data () {
		return {
			model: "호반",
			dialog: false,
			data: {},
			btnAgree: false
		}
	},
	mounted()
	{
		this.$vuetify.theme.dark = false;
	},
	beforeDestroy() {
		this.$vuetify.theme.dark = true;
	},
	methods:
	{
		HANDLE_AGREE()
		{
			const userAgent = navigator.userAgent || navigator.vendor
			if (/android/i.test(userAgent)) {
				location.href = 'kakaoweb://closeBrowser';
			} else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
				location.href = 'kakaotalk://inappbrowser/close';
			}
		}
	}
});
